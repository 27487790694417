import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser'

const Join = () => {
      const form =useRef()

      const sendEmail = (e) => {
            e.preventDefault();
        
            emailjs
              .sendForm('service_ggqehgb', 'template_vtlifyl', form.current, {
                publicKey: 'HExUTlT9zhoNZq2Br',
              })
              .then(
                () => {
                  console.log('SUCCESS!');
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );
          };
  return (
      <div className="Join" id="join-us">
           <div className="left-j">
          <div>
            <span className='stroke-text'>READY TO</span>
             <span> LEVEL UP</span>
             </div>
            <div>
           <span>YOUR BODY</span>
           <span className="stroke-text"> WITH US?</span>
           </div>
           </div>
           <div className="right-j">
            <form ref={form}action="" className="email-container" onSubmit={sendEmail}>
                  <input type="email" name="from_name" placeholder="Enter your Email address"/>
                  <button className="btn btn-j">Join Now</button>
                  </form>
            </div>
           </div>
      
  );
};

export default Join
