import React,{ useState }  from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from'../../assets/bars.png'
import {Link} from "react-scroll"


const Header = () => {
  const mobile=window.innerWidth<=768? true:false;
  const [menuOpened,setMenuOpned]=useState(false)
  return (
    <div className="header">
      <img src={Logo} alt="" className='logo'/>
      {menuOpened===false&&mobile===true?(
           <div
           style={{backgroundColor: 'var(--appColor)',
           padding:'0.5rem',
           borderRadius:'5px',
           }}
           onClick={()=>setMenuOpned(true)}
           >
            <img
             src={Bars} 
             alt=""
              style={{width: '1.5rem',height:  '1.5rem'}}
            />
            </div>
      ):(
      
      <ul className='header-menu'>
        <li>
          <Link 
          onClick={()=>setMenuOpned(false)}

          to='home'
          span={true}
          smooth={true}
          > Home</Link>
         </li>
        <li onClick={()=>setMenuOpned(false)}>Programs</li>
        <li onClick={()=>setMenuOpned(false)}>Why us</li>
        <li onClick={()=>setMenuOpned(false)}>Plans</li>
        <li><Link 
        onClick={()=>setMenuOpned(false)}
        to='testtimonials'
        span={true}
        smooth={true}
          >Testimonials</Link></li>
        <li>Testimonials</li>
      </ul>
      )}
    </div>
  );
};

export default Header
